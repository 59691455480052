.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.hidden{
  display: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  overflow-x: hidden;
  max-width: 100vw;
  margin: 0px;
  padding: 0px;
}

@media only screen and (min-width: 275px) and (max-width: 767px) {
  /*.custom-table-1-by-3 td ,   .custom-table-1-by-3 th {*/
  /*  min-width: 33% !important;*/
  /*  max-width: 33% !important;*/
  /*}*/
  .custom-table-1-by-3 .euiTableRow > .euiTableRowCell:first-child{
    min-width: 20%;
    max-width: 20%;
  }
  .custom-table-1-by-3 .euiTableRow > .euiTableRowCell:nth-child(2){
    min-width: 30%;
    max-width: 30%;
  }
  .custom-table-1-by-3 .euiTableRow > .euiTableRowCell:last-child{
    min-width: 50%;
    max-width: 50%;
  }

  .custom-table-1-by-3 .euiTableRow > .euiTableRowCell > div{
    padding: 2px;
  }
}